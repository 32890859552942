.hero {
    .swiper-container {
    }

    .swiper-slide {
        img {
            object-fit: cover;
            height: calc(80vh - #{$header-height-mobile});

            @include for-tablet-landscape-up {
                height: calc(100vh - #{$header-height-desktop} - 15rem);
            }
        }
    }

    .swiper-pagination {
        position: absolute;
        left: 50%;
        bottom: 3rem;
        transform: translateX(-50%);
        z-index: 1;
        display: flex;

        &-bullet {
            display: block;
            border-radius: 100%;
            background: white;
            margin-left: 0.4rem;
            margin-right: 0.4rem;

            width: 1rem;
            height: 1rem;

            &-active {
                background: $color-brand;
            }
        }
    }
}

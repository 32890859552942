html {
    font-size: 62.5%;
}

body {
    color: $color-text-dark;
    font-size: 1.5rem;
    font-family: $font-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    
    &.nav-open {
        overflow: hidden;
        @include for-tablet-landscape-up {
            overflow: visible;
        }
    }
}

#wrapper {
   
}

#newsflash-alert {
    background-color: $color-brand;
    color: $color-text-light;
    padding: 15px 0;
    text-align: center;
    font-weight: $font-weight-semibold;
}

#content {
    flex: 1 0 auto;
}

img {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // color: $color-black;
    font-family: $font-title;
}

address {
    font: inherit;
}

a {
    color: inherit;
    transition: all 0.2s ease-in-out;
    text-decoration-color: transparent;
    text-underline-position: under;

    &:hover {
        text-decoration-color: currentColor;
    }
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter-mobile;
    padding-right: $gutter-mobile;
    max-width: $container-width + $gutter-mobile * 2;

    @include for-tablet-landscape-up {
        padding-left: $gutter-tablet;
        padding-right: $gutter-tablet;
        max-width: $container-width + $gutter-tablet * 2;
    }

    @include for-desktop-up {
        padding-left: $gutter-desktop;
        padding-right: $gutter-desktop;
        max-width: $container-width + $gutter-desktop * 2;
    }

    &--small {
        max-width: $container-width-small + $gutter-mobile * 2;

        @include for-tablet-landscape-up {
            max-width: $container-width-small + $gutter-tablet * 2;
        }

        @include for-desktop-up {
            max-width: $container-width-small + $gutter-desktop * 2;
        }
    }

    &--medium {
        max-width: $container-width-medium + $gutter-mobile * 2;

        @include for-tablet-landscape-up {
            max-width: $container-width-medium + $gutter-tablet * 2;
        }

        @include for-desktop-up {
            max-width: $container-width-medium + $gutter-desktop * 2;
        }
    }
}

::-moz-selection {
    color: white;
    background-color: $color-brand;
}

::selection {
    color: white;
    background-color: $color-brand;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@function url-encode($string) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D",
    );
    $new: #{$string};
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}

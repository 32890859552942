.page--home {
}

.home-cta {
    margin-top: 7rem;
    margin-bottom: 7rem;

    @include for-tablet-landscape-up {
        margin-top: 8.5rem;
        margin-bottom: 8.5rem;
    }

    .cta-columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: -8rem;
        margin-left: -4rem;

        @include for-tablet-landscape-up {
            margin-top: -6.5rem;
            margin-left: -6.5rem;
        }

        &__item {
            width: 100%;
            padding-top: 8rem;
            padding-left: 4rem;

            @include for-tablet-landscape-up {
                padding-top: 6.5rem;
                padding-left: 6.5rem;
                width: 50%;
            }
        }
    }

    .cta-column {
        border: 1px solid $color-brand;
        border-radius: 0.3rem;
        padding: 4rem;
        padding-top: 6.75rem;
        text-align: center;
        position: relative;

        @include for-tablet-landscape-up {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: $color-brand;
        }

        &__title {
            @include heading-1();
            color: inherit;
            margin-bottom: 1.875rem;
        }

        &__text {
            @include body-text();
            color: inherit;
            margin-bottom: 1.8rem;
        }

        &__button {
            @include for-tablet-landscape-up {
                align-self: center;
                margin-top: auto;
            }
        }
        &__hours {
            margin: 0 auto;
            margin-bottom: 2.8rem;
            text-align: left;
            line-height: 1.5;

            tr {
                td {
                    &:first-child,
                    &:nth-child(2) {
                        padding-right: 1.6rem;
                    }
                }
                &.today {
                    font-weight: $font-weight-bold;
                }
            }
        }
        &--contact {
            color: white;
            background-color: $color-brand;

            .cta-column {
                &__button {
                    @include button-outline-white();
                }
            }
        }

        &--order {
            color: $color-brand;

            .cta-column {
                &__text {
                    color: $color-text-dark;
                }
                &__button {
                    @include button-outline-brand();
                }
            }
        }
    }
}

.home-team {
    margin-top: 4rem;
    margin-bottom: 4rem;

    @include for-tablet-landscape-up {
        margin-top: 8.5rem;
        margin-bottom: 8.5rem;
    }

    .container {
        @include for-tablet-landscape-up {
            display: flex;
        }
    }

    &__body {
        width: 100%;
        margin-bottom: 2.4rem;

        @include for-tablet-landscape-up {
            margin-bottom: 0;
            padding-top: 5rem;
            padding-right: 5rem;
            width: 50%;
        }

        @include for-desktop-up {
            padding-right: 10rem;
            width: percentage(4/12);
        }
    }

    &__team {
        width: 100%;

        @include for-tablet-landscape-up {
            width: 50%;
        }

        @include for-desktop-up {
            width: percentage(8/12);
        }

        .team-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -2.4rem;
            margin-left: -1.6rem;

            &__item {
                width: 50%;
                padding-top: 2.4rem;
                padding-left: 1.6rem;
            }
        }

        .team-card {
            &__image {
                margin-bottom: 1.8rem;
            }

            &__name,
            &__function {
                display: block;
                color: $color-brand;
                font-size: 1.6rem;
                font-weight: $font-weight-regular;
                font-family: $font-text;

                @include for-tablet-landscape-up {
                    font-size: 1.8rem;
                }
            }
            &__name {
                margin-bottom: 0.4rem;

                @include for-tablet-landscape-up {
                    margin-bottom: 0.8rem;
                }
            }

            &__function {
                font-style: italic;
            }
        }
    }

    &__title {
        @include heading-1();
        margin-bottom: 2.5rem;
    }

    &__text {
        @include body-text();
    }
}

// Colors //
$color-brand: #789b8d; // #97C1AA
$color-text-dark: #3a4f4a;
$color-text-medium: #9F9F9F;
$color-text-light: white;
$color-placeholder: #dadada;
$color-error: #cc2222;

// Fonts //
$font-title: "Times", "Times new roman", serif;
$font-text: "Open sans", sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Container
$container-width: 144rem;
$container-width-medium: 102.1rem;
$container-width-small: 90.75rem;

// Gutters
$gutter-mobile: 2rem;
$gutter-tablet: 5rem;
$gutter-desktop: 10.5rem;

// Header
$header-height-mobile: 6.75rem;
$header-height-desktop: 9rem;

// z-indexes
$z-header: 100;
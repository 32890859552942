@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

// Freeform
.ff-errors {
    display: none;
}

.ff-form {
    &-errors,
    &-success {
        padding: 1.2rem 2.4rem;
        margin-bottom: 2.4rem;
        border-width: 1px;
        border-style: solid;
        border-radius: 0.3rem;
    }

    &-errors {
        border-color: $color-error;
        color: white;
        background-color: lighten($color-error, 15%);
    }

    &-success {
        border-color: $color-brand;
        color: white;
        background-color: lighten($color-brand, 15%);
    }
}

.form {
    &__row {
        display: flex;
        align-items: flex-end;
    }

    &__group {
        flex-grow: 1;
        margin-bottom: 2rem;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    &__label {
        display: none;

        &.is-required {
            &::after {
                content: " *";
            }
        }
        &--visible {
            color: $color-text-dark;
            font-size: 1.5rem;
            margin-bottom: 0.2rem;
            display: block;
        }
    }

    &__input {
        appearance: none;
        background-color: white;
        border: 0.1rem solid $color-brand;
        border-radius: 0.3rem;
        outline: none;
        line-height: 1.35;
        width: 100%;
        padding: 0.8rem 1.7rem;
        font-size: 1.5rem;
        font-family: $font-text;
        font-weight: 400;
        transition: border-color 0.2s ease-in-out;

        @include placeholder {
            color: $color-text-dark;
        }

        &:focus {
            border-color: $color-brand;
        }

        &.ff-has-errors,
        &--has-errors {
            border-color: $color-error;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    select.form__input {
        background-repeat: no-repeat;
        background-position: center right 1.1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.28' height='8.16' viewBox='0 0 14.28 8.16' fill='#{url-encode($color-brand)}'%3E%3Cpath d='M7.15,5.71,12.54.31A1,1,0,0,1,14,.31h0a1,1,0,0,1,0,1.45L7.87,7.87a1,1,0,0,1-1.4,0L.32,1.76A1,1,0,0,1,1.72.28s0,0,0,0Z' /%3E%3C/svg%3E");

        &:not[disabled] {
            cursor: pointer;
        }

        &::-ms-expand {
            display: none;
        }
    }

    &__radio {
        // Group of radios has some extra margin
        &-group {
            margin-top: 1.6rem;
            margin-bottom: 1.6rem;

            .form__radio {
                display: block;

                @include for-tablet-portrait-up {
                    display: inline-block;
                }

                &:not(:last-child) {
                    margin-bottom: 1.2rem;
                    @include for-tablet-portrait-up {
                        margin-right: 2rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
        /* Customize the label (the container) */
        display: block;
        position: relative;
        padding-left: 1.6rem + 0.45rem;
        // margin-bottom: 12px;

        cursor: pointer;
        font-size: 1.6rem;
        user-select: none;

        /* Hide the browser's default radio button */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 1.6rem;
            width: 1.6rem;
            background-color: #fff;
            border: 1px solid $color-brand;
            border-radius: 50%;
            transition: border-color 0.2s ease-in-out;

            &::after {
                content: "";
                position: absolute;
                opacity: 0;
                top: 0.2rem;
                left: 0.2rem;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background: $color-brand;
                transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
            }
        }

        .label {
            display: inline-block;
            line-height: 1;
            color: $color-placeholder;
            transition: color 0.2s ease-in-out;
        }
        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark::after {
            opacity: 0.5;
        }

        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .checkmark {
            border-color: $color-brand;
            &:after {
                opacity: 1;
                background-color: $color-brand;
            }
        }

        input:checked ~ .label {
            color: #000;
        }

        input {
            &:focus ~ .checkmark {
                box-shadow: 0 0 0 0.3rem rgba($color-brand, 0.16);
            }

            &.ff-has-errors {
                ~ .checkmark {
                    border-color: $color-error;

                    &::after {
                        background: $color-error;
                    }
                }

                ~ .label {
                    color: $color-error;
                }
            }
        }
    }

    &__checkbox {
        /* Customize the label (the container) */
        display: block;
        position: relative;
        padding-left: 1.6rem + 0.8rem;
        // margin-bottom: 12px;

        cursor: pointer;
        font-size: 1.5rem;
        line-height: 1.57;
        user-select: none;

        /* Hide the browser's default radio button */
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0.3rem;
            left: 0;
            height: 1.6rem;
            width: 1.6rem;
            background-color: #fff;
            border: 1px solid $color-brand;
            border-radius: 0.2rem;
            transition: border-color 0.2s ease-in-out;

            &::after {
                content: "";
                position: absolute;
                opacity: 0;
                top: 0.2rem;
                left: 0.2rem;
                width: 1rem;
                height: 1rem;
                border-radius: 0.2rem;
                background: $color-brand;
                transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
            }
        }

        .label {
            display: inline-block;
            // line-height: 1;
            color: $color-text-dark;
            transition: color 0.2s ease-in-out;
        }

        /* On mouse-over, add a grey background color */
        &:hover input[type="checkbox"] ~ .checkmark::after {
            opacity: 0.5;
        }

        /* Show the indicator (dot/circle) when checked */
        input[type="checkbox"]:checked ~ .checkmark {
            border-color: $color-brand;
            &:after {
                opacity: 1;
                background-color: $color-brand;
            }
        }

        input[type="checkbox"] {
            &:focus ~ .checkmark {
                box-shadow: 0 0 0 0.2rem rgba($color-brand, 0.16);
            }
            &.ff-has-errors {
                ~ .checkmark {
                    border-color: $color-error;

                    &::after {
                        background: $color-error;
                    }
                }

                ~ .label {
                    color: $color-error;
                }
            }
        }
    }

    &__file {
        margin-top: 0.5rem;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            + label {
                @include button-brand();
            }

            &:disabled {
                + label {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }

            &:not([disabled]) {
                &:focus + label {
                    box-shadow: 0 0 0 0.2rem rgba($color-brand, 0.16);
                }
            }

            &.ff-has-errors {
                + label {
                    border-color: $color-error;
                    // color: $color-error;
                }
            }
        }
    }

    &__submit {
        @include button-brand();
    }
}

@mixin heading-base() {
    display: block;
    font-family: $font-title;
    color: $color-brand;
    line-height: 1;
}

@mixin heading-1() {
    @include heading-base();
    font-size: 2.4rem;
}

@mixin heading-2() {
    @include heading-base();
    font-size: 1.95rem;
    line-height: 1;
}

@mixin body-text() {
    color: $color-text-dark;
    font-size: 1.5rem;

    h2,
    h3 {
        @include heading-base();

        &:first-child {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 1.95rem;
        margin-bottom: 2.4rem;
        margin-top: 2.4rem;

        @include for-tablet-landscape-up {
            margin-top: 5.2rem;
        }
    }

    h3 {
        font-size: 1.8rem;
        margin-top: 3.8rem;
        margin-bottom: 1.6rem;
    }

    p,
    ol,
    ul {
        line-height: 1.6;
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        li {
            position: relative;
            padding-left: 1.8rem;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    ul {
        li {
            &::before {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                background: currentColor;
                border-radius: 100%;
                top: 1rem;
            }
        }
    }

    ol {
        counter-reset: section;

        li {
            counter-increment: section;

            &::before {
                content: counters(section, ".") ".";
                font-weight: $font-weight-bold;
            }

            li {
                padding-left: 3rem;
            }
        }
    }
}

.page--standby {
    .standby-content {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include for-tablet-landscape-up {
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .intro {
            width: 100%;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                max-width: 50%;
                margin-bottom: 6rem;
            }

            &__title {
                @include heading-1();
                margin-bottom: 0.5rem;
            }

            &__text {
                @include body-text();
                color: $color-brand;
            }
        }

        .services {
            margin-bottom: 2.4rem;
            @include for-tablet-landscape-up {
                margin-bottom: 9rem;
            }

            &__list {
                margin-top: -3.6rem;
                margin-left: -2.4rem;
                @include for-tablet-landscape-up {
                    display: flex;
                }
            }

            &__item {
                width: 100%;
                padding-top: 3.6rem;
                padding-left: 2.4rem;

                @include for-tablet-landscape-up {
                    width: percentage(1/3);
                }
            }

            .service-card {
                @include for-tablet-landscape-up {
                    text-align: center;
                }
                &__icon {
                    color: $color-brand;
                    margin-bottom: 3rem;

                    svg {
                        display: inline-block;
                        max-height: 8.1rem;
                        max-width: 8.1rem;
                    }
                }

                &__text {
                    @include body-text();
                }
            }
        }

        .useful-links {
            &__title {
                @include heading-1();
                margin-bottom: 0.5rem;
            }

            &__text {
                @include body-text();
            }
        }
    }
}

.page--contact {
    #map {
        height: 60vw;

        @include for-tablet-landscape-up {
            height: 52.4rem;
        }

        .mapboxgl-canvas {
            outline: none;
        }
    }

    .contact-content {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include for-tablet-landscape-up {
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .container {
            @include for-tablet-landscape-up {
                display: flex;
                flex-direction: row;
            }
        }

        &__form {
            width: 100%;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
                width: 60%;
            }
        }

        &__info {
            @include for-tablet-landscape-up {
                padding-left: 10%;
                flex-grow: 1;
            }
        }

        &__title {
            @include heading-1();
            margin-bottom: 2rem;
        }

        &__text {
            @include body-text();

            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6rem;
            }
        }

        &__hours {
            tr {
                td {
                    &:first-child,
                    &:nth-child(2) {
                        padding-right: 1.6rem;
                    }
                }
                &.today {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}

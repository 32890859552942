.text-error-content {
    margin-top: 4rem;
    margin-bottom: 4rem;

    @include for-tablet-landscape-up {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }

    text-align: center;
    
    h1 {
        @include heading-1();
        margin-bottom: 2.4rem;
    }

    .text {
        @include body-text();
        margin-bottom: 2.4rem;
    }

    .button {
        @include button-brand;
    }
}

@mixin button-base() {
    outline: none;
    display: inline-block;
    text-decoration: none;

    font-family: $font-text;
    font-size: 1.5rem;
    font-weight: $font-weight-semibold;
    transition: all 0.2s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.3rem;

    padding: 1.35rem 4.8rem;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:not([disabled]) {
        cursor: pointer;
    }
}

@mixin button-brand() {
    @include button-base();
    background-color: $color-brand;
    border-color: $color-brand;
    color: white;

    &:not([disabled]) {
        &:hover {
            background-color: white;
            color: $color-brand;
            border-color: $color-brand;
        }
    }
}

@mixin button-outline-brand() {
    @include button-base();
    color: $color-brand;
    border-color: $color-brand;

    &:not([disabled]) {
        &:hover {
            background-color: $color-brand;
            color: white;
        }
    }
}

@mixin button-outline-white() {
    @include button-base();
    color: white;
    border-color: white;
    background-color: transparent;

    &:not([disabled]) {
        &:hover {
            background-color: white;
            border-color: white;
            color: $color-brand;
        }
    }
}

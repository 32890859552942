.footer {
    background-color: $color-brand;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @include for-tablet-landscape-up {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    color: white;
    line-height: 1.35;
    .container {
        @include for-tablet-portrait-up {
            display: flex;
        }
    }

    .logo {
        display: block;
        margin-bottom: 2.4rem;

        @include for-tablet-portrait-up {
            margin-bottom: 0;
            margin-right: 4rem;
        }

        @include for-tablet-landscape-up {
            margin-right: 13.7rem;
        }

        svg {
            display: inline-block;
            width: auto;
            height: 3rem;

            @include for-tablet-landscape-up {
                height: 3.8rem;
            }
        }
    }

    .address {
        margin-bottom: 2.4rem;

        @include for-tablet-portrait-up {
            margin-bottom: 0;
            margin-right: 4rem;
        }

        @include for-tablet-landscape-up {
            margin-right: 20rem;
        }
    }


    .info {
        margin-bottom: 2.4rem;
        
        @include for-tablet-landscape-up {
            margin-bottom: 0;
            margin-right: 4rem;
        }
    }
    .facebook {
        &:hover {
            opacity: 0.5;
        }

        @include for-tablet-portrait-up {
            margin-left: auto;
        }
    }
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: white;
    // border-bottom: 1px solid lighten($color-text-medium, 25%);
    border-bottom: 1px solid $color-text-medium;
    height: $header-height-mobile;
    z-index: $z-header;

    @include for-tablet-landscape-up {
        height: $header-height-desktop;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        display: inline-block;

        svg {
            width: auto;
            height: 3rem;
            color: $color-brand;

            @include for-desktop-up {
                height: 3.8rem;
            }
        }
    }

    .main-nav {
        position: fixed;
        top: $header-height-mobile;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        padding: $gutter-mobile;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
        opacity: 0;
        visibility: hidden;

        body.nav-open & {
            opacity: 1;
            visibility: visible;
        }

        @include for-tablet-landscape-up {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            padding: 0;
            flex-direction: row;
            justify-content: flex-start;
            background-color: transparent;
            transition: none;
            opacity: 1;
            visibility: visible;
        }

        &__item,
        &__facebook {
            display: block;

            @include for-tablet-landscape-up {
                display: flex;
                align-items: center;
                text-decoration: none;
            }

            &:not(:first-child) {
                margin-top: 2.4rem;

                @include for-tablet-landscape-up {
                    margin-top: 0;
                    margin-left: 4.8rem;
                }

                @include for-desktop-up {
                    margin-left: 6.4rem;
                }
            }
        }

        &__item {
            color: $color-text-medium;
            font-size: 1.5rem;
            text-decoration-color: transparent;
            
            @include for-tablet-landscape-up {
                height: $header-height-desktop;
                border-bottom: 2px solid transparent;
            }

            &:hover,
            &--active {
                color: $color-brand;
                text-decoration-color: $color-brand;
                
                @include for-tablet-landscape-up {
                    text-decoration-color: transparent;
                    border-bottom-color: $color-brand;
                }
            }
        }

        &__facebook {
            color: $color-brand;

            svg {
                display: inline-block;
            }
        }
    }

    .nav-toggler {
        order: 2;
        display: inline-block;
        @include for-tablet-landscape-up {
            display: none;
        }
        transform: rotate(0deg);
        appearance: none;
        height: 20px;
        width: 20px;
        cursor: pointer;
        background: none;
        border: none;
        z-index: 101;
        outline: none;
        &:hover,
        &:active,
        &:focus {
            outline: none;
        }
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: $color-brand;
            border-radius: 15px;
            opacity: 1;
            left: 0;
            outline: none;
            transform: rotate(0deg);
            transition: all 0.25s ease-in-out;
            &:nth-child(1) {
                top: 2px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 9px;
            }
            &:nth-child(4) {
                top: 16px;
            }
        }
        body.nav-open & {
            span:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2) {
                transform: rotate(45deg);
            }
            span:nth-child(3) {
                transform: rotate(-45deg);
            }
            span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

.page--order {
    .order-content {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include for-tablet-landscape-up {
            margin-top: 4.8rem;
            margin-bottom: 4.8rem;
        }

        .intro {
            width: 100%;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                max-width: 50%;
                margin-bottom: 6rem;
            }

            &__title {
                @include heading-1();
                margin-bottom: 0.5rem;
            }

            &__text {
                @include body-text();
            }
        }

        .content {
            display: flex;
            flex-direction: column-reverse;

            @include for-tablet-landscape-up {
                flex-direction: row;
            }

            &__form {
                width: 100%;

                @include for-tablet-landscape-up {
                    width: 50%;
                }
            }

            &__text {
                margin-bottom: 2.4rem;

                @include for-tablet-landscape-up {
                    margin-bottom: 0;
                    padding-left: 10%;
                    width: 40%;
                }
                @include body-text();
            }
        }
    }
}
